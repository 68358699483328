import axios from "@/axios.js";

export default {
  getDashboardPassword({commit}){
    // console.log("data", data);
    return new Promise((resolve, reject) => {
      axios
        .get("auth-drcc/dashboard-password")
        .then((response) => {
          // console.log("response_dealer", response);
          commit('GET_DASHBOARD_PASSWORD',response.data.data)
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateDashboardPassword({commit},payload){
    // console.log("data", data);
    return new Promise((resolve, reject) => {
      axios
        .put("auth-drcc/dashboard-update", payload)
        .then((response) => {
          // console.log("response_dealer", response);
          commit('GET_DASHBOARD_PASSWORD',response.data.data)
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getDealer({ commit }, data) {
    // console.log("data", data);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-dealer/list", data)
        .then((response) => {
          // console.log("response_dealer", response);
          commit("GET_DEALER_DASHBOARD", response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getSalesAdmin({ commit }, data) {
    // console.log("data", data);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-sales-admin/list", data)
        .then((response) => {
          // console.log("response_dealer", response);
          commit("GET_SALES_ADMIN", response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },

  getDoCreated({ commit }, data) {
    console.log("getDoCreated", data);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-dealer/created", data)
        .then((response) => {
          // console.log("DO_CREATED", response);
          commit("GET_DO_CREATED", response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getDoProcessing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-dealer/processing", data)
        .then((response) => {
          // console.log("GET_DO_PROCESSING", response);
          commit("GET_DO_PROCESSING", response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getDelivered({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-dealer/delivered", data)
        .then((response) => {
          // console.log("GET_DELIVERED", response);
          commit("GET_DELIVERED", response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getWarranty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/dashboard-dealer/warranty", data)
        .then((response) => {
          // console.log("GET_WARRANTY", response);
          commit("GET_WARRANTY", response.data.data);
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  filterDOCreatedDashboard({ commit }, params) {
    // console.log("PARAMS", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/created", {
          params: { ...params },
        })
        .then((response) => {
          commit("SET_FILTER_DASHBOARD", response.data.data);
          resolve(response);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },
  filterDOProcessingDashboard({ commit }, params) {
    // console.log("PARAMS", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/processing", {
          params: { ...params },
        })
        .then((response) => {
          commit("SET_FILTER_DASHBOARD", response.data.data);
          resolve(response);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },
  filterDeliveredDashboard({ commit }, params) {
    // console.log("PARAMS", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/delivered", {
          params: { ...params },
        })
        .then((response) => {
          commit("SET_FILTER_DASHBOARD", response.data.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },

  filterRegisWarranty({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/warranty", {
          params: { ...params },
        })
        .then((response) => {
          // console.log("response_warr", response);
          commit("SET_FILTER_DASHBOARD", response.data.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },

  detailDOProcessing({ commit }, params) {
    // console.log("params", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/detailprocessing", {
          params: { ...params },
        })
        .then((response) => {
          // console.log("response_warr", response);
          commit("SET_FILTER_DASHBOARD", response);
          resolve(response);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },

  detailDOCreated({ commit }, params) {
    // console.log("params", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/dashboard-dealer/detailcreated", {
          params: { ...params },
        })
        .then((response) => {
          // console.log("response_warr", response);
          commit("SET_FILTER_DASHBOARD", response);
          resolve(response);
        })
        .catch((err) => {
          reject({ err });
        });
    });
  },
};
